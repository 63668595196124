import React from 'react';
//import {IconScroll} from '../../components';
import './Hero.css';
//import images from '../../constants/images'


// eslint-disable-next-line no-lone-blocks
{/*const logos = [ 'wesell', 'cove', 'pere', 'trocah'];*/}

const Hero = () => {

  return (
    <div className="hero">
      <div className='container'>
        <div className="row align-items-center">
          <div className="col-md-6 col-12 text">
            <h1 className="title" id='slidel'>Consulting With Ease</h1>
            <p className="py-4" id='slider'>We are championing the course to scale businesses in the best sustainable way. We craft bespoke services and products to fit your business needs.</p>
            <button className="btn-positivus"><a className='book' href='#quote'>Book a consultation</a></button>
          </div>
          {/*<div className="col-md-6 col-12 mt-md-0 mt-4">
            <img className="img-fluid" src={images.services05} alt="design" />
          </div>*/}
        </div>

        {/*<Headings title="Our Clients"/>
        
        <div className="clients">
        {logos.map((logo, index) => (
        <img
          key={index}
          src={images[logo]}
          alt={images[logo]}
        />
      ))}
    
        </div>
        <IconScroll />*/}
      </div>
      
    </div>
    
  )
}

export default Hero