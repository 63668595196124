import React, {useRef} from 'react'
import { Headings } from '../../components';
import './Contact.css';
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
  const Contact = () => {
    const formRef = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_gxegmyc', 'template_yy9ouoj', formRef.current, {
          publicKey: '7ipNxBOPvoH3E3oVn',
        })
        .then(
          (result) => {
            console.log('SUCCESS!', result.text);
            toast.success('Email sent successfully!');
          },
          (error) => {
            console.log('FAILED...', error.text);
            toast.error('Failed to send email. Please try again later.');
          },
        );
    };
  
    return (
      <div className="section-padding">
        <Headings title="Contact Us" text="Connect with Us:" />

        <div className="contact" id="quote">
          <ToastContainer />
          <div className="row">
            <div className="col-md-6 col-12">
              <form ref={formRef} onSubmit={sendEmail}>
                <div class="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="individual"/>
                  <label className="form-check-label" htmlFor="inlineRadio1">Individual</label>
                </div>
                <div class="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Student"/>
                  <label className="form-check-label" htmlFor="inlineRadio2">Student</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="business"/>
                  <label className="form-check-label" htmlFor="inlineRadio3">Business</label>
                </div>

                <div className="form-group">
                  <label htmlFor='name'>Name*</label>
                  <input required className="form-control" type="text" placeholder="Name" name='name'/>
                </div>
                <div className="form-group">
                  <label required htmlFor='email'>Email*</label>
                  <input className="form-control" type="email" placeholder="Email" name='email'/>
                </div>
                <div className="form-group">
                  <label htmlFor='phone'>Phone*</label>
                  <input required className="form-control" type="tel" placeholder="Phone No." name='phone'/>
                </div><div className="form-group">
                  <label htmlFor='location'>Location*</label>
                  <input required className="form-control" type="text" placeholder="Location" name='location'/>
                </div>
                <div class="form-group">
                  <label htmlFor="exampleFormControlTextarea1">    Message*</label>
                  <textarea required class="form-control" id="exampleFormControlTextarea1" rows="3" name='message'></textarea>
                </div>
                <button type="submit" class="btn-positivus w-100" value="send">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  


export default Contact