import React from 'react';
import './CTA.css';

const CTA = () => {
  return (
    <div className="section-padding">
      <div className="row cta cta2">
        <div className="col-lg-8 col-12">

          <h3>Our Mission</h3>

          <p className="pt-2 pb-4">To provide sustainable solutions and growth strategies as a leverage to scale businesses.</p>

          <h3>Our Vision</h3>

          <p className="pt-2 pb-4">To be a global consulting brand that drives sustainable growth for businesses.</p>

          

          <h3>Our Core Values</h3>

          <p className="pt-2 pb-4">
            <ul>
              <li>Innovation</li>
              <li>Professionalism
              </li>
              <li>Collaboration 
              </li>
              <li>Sustainability 
              </li>
              <li>Continuous improvement</li>
            </ul>
          </p>

        </div>
        {/*<div className="col-lg-4 d-lg-flex d-none">
        <img src={images.thingshappen} alt="thingshappen" />
        </div>*/}
      </div>
    </div>
  )
}

export default CTA