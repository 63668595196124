import 'bootstrap/dist/css/bootstrap.min.css';
import { Menu } from './components';
import { Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import AboutPage from './pages/AboutPage';
import EventsPage from './pages/EventsPage';
import ServicePage from './pages/ServicePage';
import { Footer } from './container';




const App = () => (
  <div>
    <Menu />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/service" element={<ServicePage />} />
      <Route path="/events" element={<EventsPage />} />
    </Routes>
    <Footer/>
  </div>
);

export default App;
