import React from 'react';
import './CTA.css';

const History = () => {
  return (
    <div className="section-padding">
      <div className="row cta cta3">
        <div className="col-lg-8 col-12">
          <h3>History</h3>

          <p className="pt-2 pb-4">MC HERITAGE CONSULTING LIMITED  was founded by two gentlemen Chukwuemeka Osuji and Chukwuemeka Nwachukwu, who both professionals in Accounting, International business and management , project management and Artificial Intelligence respectively. They both shared a unique vision to start a consultancy that will help other firms grow seamlessly with a perfect mix of knowledge, experience, skills and technology. 
          The company being present in England, United Kingdom was incorporated with the company house in August 2023. From the date of incorporation till presently, the company has taken on several innovations to change the dynamics in the consulting industry.

          one of the innovative ideas of MC HERITAGE CONSULTING is establishing in  Sep 2023, the Students Heritage Club, a gig that gives a  platform to MSC students and graduates in the United Kingdom, Nigeria and around the globe, who are enthusiastic about starting a career in consulting. We give these fresh innovative minds an opportunity to birth and contribute to the positive historic changes that Mc heritage consulting makes to businesses. 

          Mc Heritage is breaking grounds as it is currently developing technology based solutions that will solve most problems that businesses face.

          We have just began our journey of a million miles , stay tuned and watch us sustainably transform businesses one at a time.</p>
        </div>
      </div>
    </div>
  )
}

export default History