import logo  from '../assets/logo.png';
import logofooter  from '../assets/logofooter.png';
import services01  from '../assets/services01.jpg';
import services02  from '../assets/services02.jpg';
import services03  from '../assets/services03.jpg';
import services04  from '../assets/services04.jpg';
import services05  from '../assets/services05.jpg';
import services06  from '../assets/services06.jpg';
import services07  from '../assets/services07.jpg';
import aboutus01  from '../assets/aboutus01.jpg';
import team02 from '../assets/team02.png';
import team03 from '../assets/team03.png';
import team04 from '../assets/team04.png';
import team01 from '../assets/team01.png';
import team00 from '../assets/team00.png';
import team05 from '../assets/team05.png';
import events01 from '../assets/events01.jpeg';
import pere from '../assets/Pere.png'
import griots from "../assets/griots.png";
import cove from "../assets/cove.png";
import wesell from "../assets/wesell.png";

export default{
logo,
logofooter,
services01,
services02,
services03,
services04,
services05,
services06,
services07,
aboutus01,
team02,
team03,
team04,
team01,
team00,
team05,
events01,
pere,
griots,
wesell,
cove,
};