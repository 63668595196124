import React from 'react';
import { Headings } from '../components';
import { data } from '../constants';
import { Contact, Process, Testimonials} from '../container';


import './Services.css';

const ServicePage = () => {
  return (
    <>
    
    <div id="services" className="d-block pt-md-4">
      <div className='service-h'>
        <div className='container'>
          <Headings title="Services" text="At our agency, we offer a range of services to help businesses grow and succeed. These services include" />
        </div>
        
      </div>
    </div>

    <div className='container'> 
    <div id="services" className="d-block pt-md-4">
      <div className="row">
        {data.ServicesData.map(({ titleone, titletwo, link, itemclass, imgURL, details }, index) => (
          <div className="col-lg-6 col-12" key={index}>
            <div className={`row ${itemclass}`}>
              <div className="col-md-6 box">
                <div>  <span>{titleone} </span>
                  <span>{titletwo}</span>
                </div>
              </div>
              <div className='p2'>
                <p>{details}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
    <div className="container">
      <Process />
      <Testimonials />
      <Contact />
    </div></>
  )
}

export default ServicePage;