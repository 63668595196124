import React from 'react';
import {Headings} from '../../components';
import './Clients.css';
import images from '../../constants/images'


// eslint-disable-next-line no-lone-blocks
const logos = [ 'wesell', 'cove', 'pere', 'griots'];

const Clients = () => {

  return (
      <div>

        <Headings title="Our Clients"/>
        
        <div className="clients">
        {logos.map((logo, index) => (
        <img
          key={index}
          src={images[logo]}
          alt={images[logo]}
        />
      ))}
    
        </div>
      </div>
    
  )
}

export default Clients;