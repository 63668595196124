import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Contact, Hero, Services, Clients } from '../container';


const Home = () => (
  <div>
    <Hero />
    <div className="container">
      <Services />
      <Clients />
      <Contact />
    </div>
  </div>
    
  );
  
  export default Home;