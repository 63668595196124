import images from './images';

const Menu = [
    {
        text: 'Home',
        link: '/',
    },
    {
        text: 'About us',
        link: '/about',
    },
    {
        text: 'Services',
        link: '/service',
    },
    {
        text: 'Events',
        link: '/events',
    },
    {
        text: 'Get Started',
        link: '#quote',
    }
];
const ServicesData = [
    {
        titleone: 'Business',
        titletwo: 'Consulting',
        link: '/service',
        itemclass: 'blight',
        imgURL: images.services01,
        details: 'We are actively consulting for businesses, providing expert services and solutions to the problems/challenges that they encounter. We ensure that we provide bespoke services and solutions tailored to the unique needs of our clients that will improve the business of our clients.',
    },
    {
        titleone: 'Marketing',
        titletwo: '& Branding',
        link: '/service',
        itemclass: 'bgreen',
        imgURL: images.services02,
        details: 'We take pride in our  expertise in strategically planning  and executing marketing and sales activities  that position our clients businessess as unique brands that stand out whilst achieving sales and  marketing objectives and driving more profit for the businessess.',
    },
    {
        titleone: 'Project',
        titletwo: 'Management',
        link: '/service',
        itemclass: 'bdark',
        imgURL: images.services03,
        details: 'we have seasoned project management professionals whom we assign to our clients to manage their projects from the project initiation, planning , execution and monitoring stages till project closure stage . Our project managers ensure that projects are delivered with best quality/standard, within budget and within project time frame.',
    },
    {
        titleone: 'Technology',
        titletwo: 'Integration',
        link: '/service',
        itemclass: 'blight',
        imgURL: images.services04,
        details: 'We are tech enthusiasts and we find a way to integrate tech-based business solutions into the operations systems or processess of our clients to increase efficiency, reduce costs and increase profits. We work to integrate our solutions into already existing sytems inplace or build an entirely new system depending on the unique needs of our clients.',
    },
]
const CaseStudies = [
    {
        text: 'For a local restaurant, we implemented a targeted PPC campaign that resulted in a 50% increase in website traffic and a 25% increase in sales.',
        link: '#',
    },
];

const WorkingProcess = [
    {
        title: 'Consultation',
        description: 'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
    },
    {
        title: 'Research and Strategy Development',
        description: 'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
    },
    {
        title: 'Implementation',
        description: 'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
    },
    {
        title: 'Monitoring and Optimization',
        description: 'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
    },
    {
        title: 'Reporting and Communication',
        description: 'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
    },
    {
        title: 'Continual Improvement',
        description: 'During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.',
    },

];

const Team = [
    {
        name: 'Chukwuemeka Osuji',
        position: ' Founder, business development manager and consultant.',
        info: '',
        foto: images.team02,
        linkedin: 'https://www.linkedin.com/in/chukwuemeka-osuji-485ba31b4',
    },
    {
        name: 'Chukwuemeka Nwachukwu',
        position: 'Co-founder & CTO',
        info: '',
        foto: images.team01,
        linkedin: 'https://www.linkedin.com/in/chukwuemekanwachukwu',
    },
    {
        name: 'Onyinye Nwachukwu',
        position: 'Project manager',
        info: '',
        foto: images.team03,
        linkedin: 'https://www.linkedin.com/in/',
    },
    {
        name: 'Uchechukwu Nwokenne (ACA)',
        position: 'Operations Manager (Africa)',
        info: '',
        foto: images.team04,
        linkedin: 'https://www.linkedin.com/in/nwokenne-uchechukwu-gnim-aca-in-view?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    },
    {
        name: 'Light Okorie',
        position: 'Social media Lead',
        info: '',
        foto: images.team05,
        linkedin: 'https://www.linkedin.com/in/light-okorie-a90895335?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    },
    

];

const Testimonials = [
    {
        name: 'Chinonso Chukwuebuka',
        position: 'Wesell bills',
        testimonial: '"I have worked with Mc Heritage Consulting a few times and they were really helpful in crafting effective and unique marketing strategies for our business. I will definitely recommend them any day anytime"',
    },
];

const Footer = [
    {
        text: 'Home',
        link: '/',
    },
    {
        text: 'About us',
        link: '/about',
    },
    {
        text: 'Services',
        link: '/service',
    },
    {
        text: 'Events',
        link: '/events',
    },
    {
        text: 'Contact Us',
        link: '#quote',
    }
];
const EventsData = [
    {
        titleone: 'Students and Graduates in Tech 2025',
        link: '/service',
        itemclass: 'blight',
        imgURL: images.events01,
        details: 'A tech conference for students and graduates',
    }
];
export default { Menu, CaseStudies, WorkingProcess, Team, Testimonials, ServicesData, EventsData, Footer };