import React, { useEffect } from 'react';
import $ from 'jquery';
import './Footer.css';
import { images, data } from '../../constants';
import { BsFacebook, BsInstagram, BsLinkedin } from 'react-icons/bs'
import Nav from 'react-bootstrap/Nav';

const Footer = () => {
  useEffect(() => {
    window.jQuery = $;
    window.$ = $;
    // Load Mailchimp validation script
    const script = document.createElement('script');
    script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Configure validation fields after the script loads
    script.onload = () => {
      window.fnames = [];
      window.ftypes = [];
      window.fnames[0] = 'EMAIL';
      window.ftypes[0] = 'email';
      window.fnames[1] = 'NAME';
      window.ftypes[1] = 'text';
      window.$mcj = $.noConflict(true);
    };

    // Clean up by removing the script if the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <footer>
      <div className="row align-items-center">
        <div className="col-md-2 col-12 footer-logo">
          <img src={images.logofooter} alt="logo" className="img-fluid" />
        </div>
        <div className="col-md-8 col-12 ">
          <ul className="navbar-footer">
            <Nav className='links'>
                {data.Footer.map((item, index) => (
                    <Nav.Link href={item.link} className='footer-link'>{item.text}</Nav.Link>
                ))}
            </Nav>
          </ul>
        </div>
        <div className="col-md-2 col-12 text-md-end fs-4 socials">
          <a href='https://www.facebook.com/share/FxZSwYavoxuTWRRN/?mibextid=LQQJ4d' target='blank'><BsFacebook /></a>
          <a href='https://www.instagram.com/mcheritage_consult/ ' target='blank'><BsInstagram /></a>
          <a href=' https://www.linkedin.com/company/mc-heritage/' target='blank'><BsLinkedin /></a>
           
        </div>
        
      </div>
      <div className="row">

        <div className="col-md-4 col-12">
          <ul className="info-contact">
            <li> <span>Contact us:</span></li>
            <li>Email: info@mcheritageconsulting.com</li>
            <li>Phone: +44 7876 519260, +234 706 559 5109 
            </li>
            <li>Address: flat 26, 63vicar lane , Bradford. West yorkshire, United kingdom. BD15BN</li>
          </ul>
        </div>
        <div className="col-md-8 col-12">
          <div id="mc_embed_shell">
            <div id="mc_embed_signup">
              <form
                action="https://mcheritageconsulting.us13.list-manage.com/subscribe/post?u=808f615ab3ecfa0528c0b19da&amp;id=b019bd5df1&amp;f_id=00d2afe2f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate row form-news"
                target="_blank"
              >
                <div id="mc_embed_signup_scroll form-tiles">
                  <div className="mc-field-group col-lg-6 col-12">
                    <input
                      type="email"
                      name="EMAIL"
                      className="required email form-control"
                      id="mce-EMAIL"
                      required
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="mc-field-group col-lg-6 col-12">
                    <input type="text" name="NAME" className="text form-control" id="mce-NAME" placeholder="Name" />
                  </div>
                  <div id="mce-responses" className="clear foot">
                    <div className="response" id="mce-error-response"></div>
                    <div className="response" id="mce-success-response"></div>
                  </div>
                  <div className="human" aria-hidden="true">
                    <input
                      type="text"
                      name="b_808f615ab3ecfa0528c0b19da_b019bd5df1"
                      tabIndex="-1"
                    />
                  </div>
                  <div className="optionalParent">
                    <div className="clear foot buttons mt-3 mt-lg-0">
                      <input
                        type="submit"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="button btn-positivus"
                        value="Subscribe To Our Newsletter"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>


          {/*<form className="row form-news">
            <div className="col-lg-6 col-12">
              <input type="email" className="form-control" id="email" placeholder="Email" />
            </div>
            <div className="col-lg-6 col-12 mt-3 mt-lg-0">
              <button type="submit" className="btn-positivus w-100">Subscribe to our newsletter</button>
            </div>
          </form>*/}

        </div>

        <div className="rights">
          <p>© 2024 Mc Heritage Consulting. All Rights Reserved.</p>
          {/* <p><a href="#" alt="Privacy Policy">Privacy Policy</a></p> */}

        </div>
      </div>
    </footer>
  )
}

export default Footer