import React from 'react';
import { Headings } from '../components';
import { data } from '../constants';
import { Contact} from '../container';


import './Events.css';

const EventsPage = () => {
  return (
    <>
    
    <div id="services" className="d-block pt-md-4">
      <div className='event-h'>
        <div className='container'>
          <Headings title="Events" text="Join our upcoming event series to stay at the forefront of trends and innovations.
These events offer valuable chances to deepen your expertise, enhance your skills, and connect with others in your field." />
        </div>
        
      </div>
    </div>

    <div className='container'> 
    <div id="services" className="d-block pt-md-4">
      <div className="row">
        {data.EventsData.map(({ titleone, titletwo, link, itemclass, imgURL, details }, index) => (
          <div className="col-12" key={index}>
            <div className={`row ${itemclass}`}>
              <div className="col-md-6 start"><img src={imgURL} alt={titleone} className="img-fluid" /></div>
              <div className="col-md-6">
                <div>  
                  <span>{titleone} </span>
                  <p className='p2'>{details}</p>
                  <span className="col-md-2 col-12 fs-4 socials">
                  <button type="submit" class="btn-positivus2 w-200 eventbutton" value="">Loading..</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
    <div className="container">
      <Contact />
    </div></>
  )
}

export default EventsPage;